
.sectionTitle {
    line-height: 1;
    font-size: 3rem;
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 1rem;
    margin-bottom: 2rem;
    color: #072248;
    width: 100%;

    &:after {
        content: "";
        display: inline-block;
        height: 3px;
        background-color: #ef3139;
        flex: 1;
    }

}

.upperCase {
    text-transform: uppercase;

}

.firstUppercase::first-letter {
    text-transform: uppercase;
}

