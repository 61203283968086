.allNews {
    background-color: $white;

    &_pagination {
        margin-bottom: 3rem;
    }

    &_wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;

        @include breakpoint-up-to($lg) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include breakpoint-up-to($md) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include breakpoint-up-to($sm) {
            grid-template-columns: repeat(1, 1fr);
        }

        .card {
            box-shadow: $boxLightShadow;
        }
    }
}