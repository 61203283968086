.nenastavno {
    .wrapper {

        display: flex;
        flex-direction: column;
        gap: 2rem;

        // item
        &_item {
            &:after {
                content: "";
                display: block;
                background-color: $gray;
                height: 1px;
                width: 100%;
                margin-bottom: 10px;
                margin-top: 10px;
            }

            .card {
                display: flex;
                flex-direction: column;
                padding: 20px;

                .title {
                    font-size: 3rem;
                    margin-bottom: 10px;
                    color: $primaryColor;
                    width: 100%;
                }

                & > div {
                    display: flex;
                    gap: 2rem;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                &_body {
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    gap: 20px;
                    flex: 1 0 calc(50% - 2rem);

                    &:hover {
                        margin: 0;
                        padding: 1rem;
                    }

                    img {
                        width: 150px;
                        height: 200px;
                        object-fit: cover;
                        border-radius: 20px;
                    }


                    .card_body_items {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;


                        .ime {
                            font-weight: bolder;
                            font-size: 2.5rem;
                            color: $primaryColor;
                        }

                        .role, .obrazovanje, .email {
                            color: rgb(122, 122, 122);
                        }

                        .email {
                            text-decoration: underline;
                        }


                    }

                    @include breakpoint-up-to($mobile) {
                        flex-direction: column !important;


                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}