.whiteBox {
    padding: 2rem;
    background-color: $white;
}

.grid {
    display: grid;

    &-col-4 {
        grid-template-columns: repeat(4, 1fr);
        gap: 4rem;
        @include breakpoint-up-to($lg) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include breakpoint-up-to($md) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint-up-to($mobile) {
            grid-template-columns: repeat(1, 1fr);

        }
    }

    &-3-1 {
        grid-template-columns: 3fr 1fr;
        gap: 2rem;
    }

    &-1-3 {
        grid-template-columns: 1fr 3fr;
        gap: 4rem;
        @include breakpoint-up-to($mobile) {
            grid-template-columns: 1fr;
        }
    }

    &-reverse {
        @include breakpoint-up-to($mobile) {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &-1-1 {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        @include breakpoint-up-to($mobile) {
            grid-template-columns: 1fr;
        }
    }

    &-1-2 {
        grid-template-columns: 1fr 2fr;
        gap: 2rem;

    }

    &-2-1 {
        grid-template-columns: 2fr 1fr;
        gap: 2rem;
        @include breakpoint-up-to($mlg) {
            grid-template-columns: 1fr;
        }
    }
}