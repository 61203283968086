.dashboard {
    display: grid;
    grid-template-areas: "sidebar header" "sidebar content";
    grid-template-columns:  auto 1fr;
    grid-template-rows: 60px 1fr;
    height: 100vh;

    &_header {
        grid-area: header;
        background-color: yellow;
        padding: 1rem;
    }

    &_sidebar {
        grid-area: sidebar;
        background-color: blue;
        display: grid;
        grid-template-rows: 60px 1fr;

        & > div {
            padding: 1rem;
            background-color: aqua;
        }
    }

    &_content {
        padding: 1rem;
        background-color: green;
        grid-area: content;
    }

    &_news {

    }
}


.textEditor {
    width: 100%;
    max-width: 800px;
    min-width: 320px;

    .ck-content {
        background-color: white;
    }

    &-submit {
        @extend .btn;
        @extend .btn-primary;
        cursor: pointer;
        margin-top: 1rem;
    }
}
