
.cls-1 {
    fill: #e5dbc1
}

.cls-1, .cls-10, .cls-11, .cls-13, .cls-15, .cls-18, .cls-19, .cls-2, .cls-20, .cls-21, .cls-5, .cls-6, .cls-7, .cls-8 {
    stroke: #000
}

.cls-1, .cls-10, .cls-13, .cls-18, .cls-19, .cls-2, .cls-20, .cls-21, .cls-5, .cls-6, .cls-7, .cls-8 {
    stroke-linejoin: round
}

.cls-1, .cls-10, .cls-11, .cls-13, .cls-15, .cls-18, .cls-19, .cls-2, .cls-20, .cls-21, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
    stroke-width: 2px
}

.cls-2 {
    fill: #fcbc1c
}

.cls-23, .cls-5, .cls-9 {
    stroke-linecap: round
}

.cls-19, .cls-23, .cls-5, .cls-9 {
    fill: none
}

.cls-15, .cls-22, .cls-6 {
    fill: #fff
}

.cls-7 {
    fill: #f2f2f2
}

.cls-8 {
    fill: #8cd1cf
}

.cls-23, .cls-9 {
    stroke: #fff
}

.cls-11, .cls-15, .cls-23, .cls-9 {
    stroke-miterlimit: 10
}

.cls-10 {
    fill: #333
}

.cls-11, .cls-13 {
    fill: #e6e6e6
}

.cls-17 {
    opacity: .2
}

.cls-18 {
    fill: #6dafac
}

.cls-20 {
    fill: #8ad1e2
}

.cls-21 {
    fill: #f9761e
}

.cls-23 {
    stroke-width: 3px
}
