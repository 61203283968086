.btn {
    display: inline-block;
    padding: 1em 2em;
    border-radius: 5px;
    outline: none;
    transition: $transition;
    cursor: pointer;

    &-primary {
        background: $primaryColor;
        color: $white;
    }

    &-light {
        background-color: $white;
        color: $black;

        &-transparent {
            border: 1px solid $black;
            background-color: rgba($white, .99);
            color: $black;
            font-weight: 700;
        }
    }

    &-outline {
    }

    &-black {
        border: 1px solid $white;
        background-color: rgba($black, .99);
    }

    &-black-transparent {
        border: 1px solid $white;
        background-color: rgba($black, .5);
    }

    &-danger {
        background: $active;
        color: $white;
    }

    &:hover {
        opacity: .8;
    }

    &-sm {
        padding: .2em 1em !important;
    }

    &-ssm {
        padding: .1em 1em !important;
    }

    &-md {
        padding: .4em 1em !important;
    }

    &-lg {
        font-size: 2rem !important;
    }

    &_rounded {
        gap: 10px;
        font-size: 1.4rem;
        font-weight: 700;
        padding: 0.9em 1.2em;
        line-height: 1;
        border-radius: 2em;
        position: relative;
        z-index: 1;
        transition: all 0.3s ease-in-out;


        &:hover {
            opacity: 1;
        }

        &:before {
            content: '';
            width: 50%;
            height: 2.5em;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
            opacity: 1;
            transition: $transition;
            border-radius: 2em;
        }

        &:hover:before {
            width: 100%;
        }

        &-gray {
            color: $primaryColor;

            &:before {
                background: rgba($gray, 0.4);
            }

            &:hover {
                color: $active;
            }
        }

        &-active {
            color: $white;

            &:before {
                background: rgba($active, 1);
            }

            &:hover {

                //color: $primaryColor;
            }
        }

        &-primary {
            color: $primaryColor;

            &:before {
                background: rgba($active, 1);
            }

            &:hover {

                color: $white;
            }
        }
    }

    // GRAY ROUNDED
    //&_gray-round {
    //    gap: 10px;
    //    font-size: 1.4rem;
    //    font-weight: 700;
    //    padding: 0.9em 1.2em;
    //    line-height: 1;
    //    border-radius: 2em;
    //    position: relative;
    //    z-index: 1;
    //    color: $primaryColor;
    //    -webkit-transition: all 0.3s ease-in-out;
    //    transition: all 0.3s ease-in-out;
    //}
    //
    //&_gray-round:hover {
    //    color: $active;
    //}
    //
    //&_gray-round:before {
    //    content: '';
    //    width: 50%;
    //    height: 2.5em;
    //    position: absolute;
    //    left: 0;
    //    top: 50%;
    //    transform: translateY(-50%);
    //    z-index: -1;
    //    opacity: 0.9;
    //    background: rgba($gray, 0.4);
    //    transition: $transition;
    //    border-radius: 2em;
    //}
    //
    //&_gray-round:hover:before {
    //    width: 100%;
    //}


    // revers button from rigth to left

    &_primary-round-reverse {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        gap: 10px;
        font-size: 14px;
        font-weight: bold;
        padding: 12px 24px;
        line-height: 1;
        border-radius: 10px;
        position: relative;
        z-index: 1;
        color: $white;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        text-decoration: none;

        &:hover {
            color: $white;

            &:before {
                width: 100%;
            }
        }

        &:before {
            content: '';
            width: 70px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
            opacity: 0.9;
            background: $active;
            transition: $transition;
            border-radius: 20px;
        }
    }

    &_active-round {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        gap: 10px;
        font-size: 1.3rem;
        font-weight: bold;
        padding: 1em 2em;
        line-height: 1;
        position: relative;
        z-index: 1;
        color: $white;
        background-color: $active;
        text-shadow: 0 0 10px $black;
        transition: all 0.3s ease-in-out;
        border-radius: 2em;

        &:hover {
            color: $white;

            &:before {
                width: 100%;
            }
        }

        &:before {
            content: '';
            width: 4em;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            opacity: 0.9;
            background: $primaryColor;
            transition: $transition;
            border-radius: 2em;
        }
    }
}