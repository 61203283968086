$xs: 0;
$mobile: 480px;
$sm: 576px;
$sm-plus: 640px;
$md: 768px;
$lg: 992px;
$mlg: 1024px;
$xl: 1200px;
$xxl: 1400px;

@mixin breakpoint-up-to($breakpoint) {
    @media (max-width: $breakpoint) {
        @content
    }
}