
.navbar {
    color: $white;
    position: fixed;
    z-index: 10;
    width: 100%;
    top: 0;
    transition: background-color $transition, padding $transition;
    padding: 2rem 0;


    &_bigmenu {
        //margin-left: 3rem;
        flex-grow: 1;

        &_language {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
        }

        & > ul {
            display: flex;
            gap: 4rem;
            font-size: 1.8rem;
            justify-content: flex-start;
            position: relative;

            li {
                cursor: pointer;
            }
        }

        &-drop {

            ul {

            }

            &_menu {
                position: absolute;
                width: 100%;
                background-color: $white;
                left: 0;
                grid-template-columns: repeat(3, 1fr);
                padding: 1rem;
                gap: 2rem;
                display: grid;
                visibility: hidden;
                opacity: 0;
                transition: all $transition;
                transform: translate(0, 20px);
                border-radius: 10px;
                color: $black;
                box-shadow: 0 0 10px 1px gray;

                span {
                    text-underline-style: dash;
                    font-weight: 700;
                    color: $primaryColor;
                }

                ul {
                    font-size: 1.6rem;
                    padding-left: 1rem;
                }
            }

            &:hover > &_menu {
                opacity: 1;
                visibility: visible;
                transform: translate(0, 10px);
            }
        }
    }


    &.scrolled {
        background-color: rgba($primaryColor, .9);
        padding: 1rem 0;
    }

    &_nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5rem;
    }

    &_brand {
        //flex-grow: 1;

        @include breakpoint-up-to($mobile) {
            padding: 1rem 0;
        }

        img {
            height: 7rem;
            @include breakpoint-up-to($mobile) {
                height: 5rem;
            }

        }
    }

    &_menu {
        & > ul {
            align-items: center;
            height: 100%;
            display: flex;
            gap: 3rem;
            font-weight: 700;

            & > li {
                width: 100%;
                height: 100%;

                & > a, & > span {
                    height: 100%;
                    @include placeCenter;
                }

                &:hover {
                    & > a, & > span {
                        color: $active;
                    }
                }
            }

            a, span {
                display: block;
                padding: 1rem;
                white-space: nowrap;
                cursor: pointer;
                transition: color $transition;
            }

            a:hover {
                color: $active;

            }
        }

        &-desktop {
            position: relative;
            //li elementu na kome je sub menu
            .drop {
                position: relative;

                &:hover {
                    & > ul {
                        opacity: 1;
                        visibility: visible;
                    }

                }

                //ul submenu items
                &_menu {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    //width: 100%;
                    background-color: rgba($primaryColor, 1);
                    opacity: 0;
                    visibility: hidden;
                    transition: $transition;

                    span {
                        text-transform: uppercase;
                        text-decoration: underline;
                        text-underline-offset: 3px;
                    }
                }
            }
        }


        &-mobile {
            position: fixed;
            right: 0;
            transform: translateX(100%);
            background-color: $white;
            top: 0;
            width: 100%;
            height: 100vh;
            line-height: 2;
            transition: $transition;
            min-width: 360px;
            color: $black;
            @include breakpoint-up-to($mobile) {
                top: 0;
            }

            &-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1rem;
                padding: 1rem;
                border-bottom: 1px solid $gray;
                background-color: $primaryColor;
                height: 92.4px;

                img {
                    height: 50px;
                }

                button {
                    font-size: 3rem;
                    color: $white;
                }

                a, button {
                    @include placeCenter;
                }
            }

            &-body {
                padding: 2rem;
            }

            .accordion {
                &_title {
                    padding: .5rem;
                    line-height: 1;
                    border-color: transparent;
                    border-radius: 0;
                }

                li {
                    line-height: 1.8;
                }


                &:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }


            &.expand {
                transform: translateX(0%);
            }

            &-submenu {
                position: fixed;
                right: 0;
                transform: translateX(100%);
                background-color: rgba(black, 1);
                padding: 2rem;
                height: 100vh;
                line-height: 2;
                transition: $transition;
                min-width: 360px;
                top: 0;

                &.expand {
                    transform: translateX(0%);
                }
            }

            .drop {
                span {
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    cursor: pointer;

                    svg {
                        font-size: .8em;
                    }
                }
            }

        }

    }

    &_hamburger {
        display: none;
        @include breakpoint-up-to($xl) {
            @include placeCenter;
            gap: 1rem;
        }

        img {
            display: block;
        }

        button {
            font-size: 2.5rem;
            @include placeCenter;
            color: $white;
            cursor: pointer;
        }
    }
}