.akreditovaniCentar {

    &_info {
        .location-map {
            box-shadow: $boxLightShadow;
            padding: 1rem;

        }

        & > div {

            font-style: normal;
            box-shadow: $boxShadow;
            padding: 2rem;
            line-height: 1.6;

            dt {
                font-weight: 600;
            }
        }
    }
}

.googleMap_100vw {
    iframe {
        width: 100%;
    }
}