.nastavno {
    min-height: 70vh;
    border-radius: 20px;
    margin-bottom: 20px;

    .teamCard {
        height: auto;

        &_info {
            min-height: unset;
        }
    }


    &_kategorije {
        display: flex;

        gap: 20px;
        margin-bottom: 40px;

        .active {
            display: flex;
            border-bottom: 1px solid $active;
            background-color: $active;
            color: $white;
            border-bottom: 1px solid $active;
            border-radius: 10px;
        }

        .inactive {
            display: flex;
        }

        a {
            padding: 10px 20px;
            cursor: pointer;
            font-size: 18px;
            color: $primaryColor;
            font-weight: bold;
            border-bottom: 1px solid $primaryColor;
            transition: $transition;

            &:hover {
                background-color: $active;
                color: $white;
                border-bottom: 1px solid $active;
                border-radius: 10px;
            }

        }

        @include breakpoint-up-to($md) {
            flex-direction: column;
            padding-bottom: 20px;
        }
    }


    &_osoblje {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 20px;

        .active {

            @include breakpoint-up-to($md) {
                grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

                p {
                    font-size: 16px;
                }
            }
        }

        .about_fakultet_item {
            display: flex;
            align-items: center;
            gap: 10px;
            flex-direction: row;
            background: $primaryColor;
            padding: 10px;
            border-radius: 10px;

            svg {
                padding: 5px;
                border-radius: 100%;
                font-size: 24px;
                background: $gray;
                width: 40px;
                height: 40px;
                color: $primaryColor;
            }

            p {
                font-size: 18px;
                color: $white;
            }

            &:hover {
                background: $active;
            }
        }

        .inactive {
            display: none;
        }
    }


}

