.newsSingle {
    display: grid;
    grid-template-columns: auto minmax(320px, 320px);
    gap: 3rem;
    @include breakpoint-up-to(960px) {
        grid-template-columns: auto;

    }

    &_content {
        position: relative;
        line-height: 1.8;
        text-align: justify;
        background-color: $white;

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        & img:not(.thumbnail) {
            width: 48%;
            height: 200px;
            display: inline;
            object-fit: cover;
            margin: 0 1% 10px;
            line-height: 1;
            @include breakpoint-up-to(740px) {
                width: 100%;
            }
        }

        img {
            display: block;
            margin: 1rem 0;
            max-height: 450px;
            object-fit: contain;
            width: 100%;
            height: auto;
        }

        &.thumbnail {
            object-fit: cover;
            padding: 0;
            margin: 0 0 2rem;
        }

        ul {
            list-style: circle inside;
        }
    }


}