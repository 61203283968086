.topbar {
    padding: 1rem 0;
    color: $white;
    width: 100%;
    font-size: 1.3rem;

    font-weight: 300;

    h1 {
        font-weight: 300;

    }

    & > article {
        display: flex;
        justify-content: space-between;
        gap: 2rem;

        div {
            //flex: 1;
        }
    }
}