.d-flex {
    display: flex;

    &.j-between {
        justify-content: space-between;
    }

    .col-md {
        &-3 {
            flex: 0 0 30%;
        }
    }
}

.d-grid {
}