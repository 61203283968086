.img {
    display: block;
    width: 100%;

    &-scale {
        &-h200 {
            height: 200px;
            width: auto;
            object-fit: contain;
            aspect-ratio: 1/1;
        }
    }

    &-center {
        margin: 0 auto;
    }

    &-circle {
        border-radius: 50%;
    }
}
