.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @include breakpoint-up-to($mobile) {
        min-height: 60vh;
    }

    &_image {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            inset: 0;
            background: rgba($black, .55);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
        }
    }

    &_content {
        position: relative;
        color: white;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        height: 100%;
        padding: 15rem 0;
        @include breakpoint-up-to($mobile) {
            padding: 7rem 0;
        }

        h1 {
            margin: 3rem 0;
            text-shadow: 0 0 3px black;
            @include breakpoint-up-to($md) {
                font-size: 4rem;
                line-height: 1.2;
            }
            @include breakpoint-up-to($sm) {
                font-size: 3rem;

            }
        }

        h2 {
            @include breakpoint-up-to($sm) {
                font-size: 2rem;
                line-height: 1.3;
            }
        }

        h3 {
            text-transform: uppercase;
            text-shadow: 0 0 3px black;
        }


    }

    &_actions {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: start;
        margin-top: 2rem;
    }
}