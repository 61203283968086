.izdavac {
    .sectionTitle {
        margin: 3rem 0;
    }

    &.udzbenici {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-shadow: $boxLightShadow;
        }

    }

    .grid {
        & > div {
            box-shadow: $boxLightShadow;
            border-radius: 20px;
        }
    }

    .book_wrapper {
        position: relative;
        perspective: 800px;

        &:hover {
            .back {
                transform: rotateY(0deg);
            }

            .front {
                transform: rotateY(-180deg);

            }
        }

        & > div {
            height: 100%;
            width: 100%;
            backface-visibility: hidden;
            transition: transform $transition;
            cursor: pointer;
            transform-style: preserve-3d;
            border-radius: 20px;
            overflow: hidden;
        }

        .front {
            z-index: 2;
            position: relative;
            //transform: rotateY(180deg);
        }

        .back {
            position: absolute;
            top: 0;
            left: 0;
            transform: rotateY(180deg);
            background-color: $primaryColor;
            color: $white;
            z-index: 1;
            padding: 10px;

            & > div {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 10px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                $border: 2px solid $active;

                &:after, &:before {
                    content: "";
                    position: absolute;
                    width: 50%;
                    height: 50%;
                }

                &:after {
                    border-top-left-radius: 10px;
                    border-top-left-radius: 10px;
                    border-top: $border;
                    border-left: $border;
                    left: 0;
                    top: 0;
                }

                &:before {
                    right: 0;
                    bottom: 0;
                    border-right: $border;
                    border-bottom: $border;
                    border-bottom-right-radius: 10px;
                }

                div {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }

            h4 {
                padding: 0 5px;
                line-height: 1.3;
                font-size: 2rem;
            }

            p, h4 {
                text-align: center;
            }
        ;
        }
    }
}