.nauka {
    display: flex;
    flex-direction: column;
    gap: 10rem;

    article {
        grid-template-areas: "text image";

        &:nth-child(even) {
            grid-template-areas: "image text";
            @include breakpoint-up-to($md) {

                grid-template-areas: "image" "text";
            }
        }

        div {
            &:nth-child(1) {
                grid-area: text
            }

            &:nth-child(2) {
                grid-area: image
            }

        }


        @include breakpoint-up-to($md) {
            grid-template-columns: 1fr;
            grid-template-areas: "image" "text";
        }
    }
}
