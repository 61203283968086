.card {
    border-radius: 10px;
    overflow: hidden;
    background-color: $white;
    color: $black;
    display: flex;
    flex-direction: column;


    &_image {
        img {
            width: 100%;
            height: 150px;
            object-fit: cover;
        }
    }

    .thumbnail {
        img {
            width: 100%;
            height: 150px;
            object-fit: cover;
        }
    }

    &_body {
        line-height: 1.6;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        transition: $transition;
        padding: 1rem;
        background: $white;
        cursor: pointer;

        &-bottom {
            padding: 1rem 0;
            display: flex;
            justify-content: space-between;
        }

        &:hover {
            margin-top: -15px;
            padding-bottom: 25px;
        }
    }

    .card_footer {
        padding: 1rem;

        .card_body-bottom {
            display: flex;
            align-items: center;

            p {
                font-size: 14px;
                cursor: pointer;
            }
        }
    }

    &_bottom {
        padding: 1rem;
    }

    h4 {
        font-size: 1.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }
}

.cardOverlay {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    height: 250px;
    cursor: pointer;
    width: 100%;

    img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }

    &_title {
        position: absolute;
        z-index: 2;
        color: $primaryColor;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: rgba($white, .8);
        padding: 1rem;
        font-size: 1.6rem;
        line-height: 1.2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 65px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &_overlay {
        position: absolute;
        top: 100%;
        height: 75%;
        padding: 10px;
        color: $white;
        background-color: rgba($white, .1);
        backdrop-filter: blur(5px);
        z-index: 3;
        transition: $transition;
        opacity: .5;
        width: 100%;

        & > div {
            background-color: rgba($primaryColor, .9);
            padding: 10px;
            height: 90%;
            z-index: 3;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            line-height: 1.3;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr auto;
            border-radius: 5px;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }


    &_footer {
        width: 100%;

        & > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &:hover &_overlay {
        top: 0;
        opacity: 1;

    }
}