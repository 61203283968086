/*----------------------------------------------------*/
/* Team Style */
/*----------------------------------------------------*/
.team {
    text-align: center;
    position: relative;
    transition: $transition;
    //max-width: 300px;
    
    cursor: pointer;

    &:hover {
        z-index: 3;
    }

    &-bg {
        background: $white;
        position: absolute;
        left: -5%;
        top: -5%;
        width: 110%;
        height: 110%;
        z-index: 1;
        opacity: 0;
        transition: $transition;
        border-radius: 15px;
        box-shadow: $boxLightShadow;

    }

    &:hover &-bg {
        opacity: 1;
    }


    /*-------------------------*/
    /* Team Image */
    /*-------------------------*/
    &-img {
        overflow: hidden;

        img {
            position: relative;
            z-index: 2;
            transform: scale(1.0);
            border-radius: 5px;
            transition: $transition;
            width: 100%;
            height: 100%;
        }
    }


    &:hover &-img img {
        transform: scale(1.1);
    }

    /*-------------------------*/
    /* Team Info */
    /*-------------------------*/
    &-info {
        position: relative;
        z-index: 2;
        padding: 20px 0 0;
    }

    &-info &-name {
        color: $primaryColor;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        transition: $transition;
    }

    &-info &-name:hover {
        color: $active;
    }

    &-info p {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 0;
    }


}


