.lastNews {
    background-color: rgba($primaryColor, .99);
    color: $white;

    h2 {
        text-align: center;
        margin-bottom: 3rem;
    }

    &_wrapper {
        
        .card {
            display: flex;
            flex-direction: column;

            &_body {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                justify-content: space-between;
                font-size: 1.3rem;

                h4 {
                    font-size: 1.4rem;
                }
            }
        }

        .readMore {
            display: block;
        }
    }

    &_actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 2rem;

        .slider_news-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }


    }
}