.pagination {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    .active {
        background-color: #888888;
        border-radius: 5px;
    }

    .box {
        font-size: 2rem;
        height: 2em;
        width: 2em;
        border-radius: 5px;
        border: 1px solid gray;
        line-height: 2em;
        text-align: center;
        display: block;
    }

    ul {
        display: flex;
        gap: .5rem;

        li {
            a {

            }
        }
    }
}