.intro {
    display: grid;
    grid-template-columns: minmax(320px, 1fr) minmax(200px, 3fr);
    gap: 3rem;
    @media (max-width: 640px) {
        grid-template-columns: repeat(1, minmax(340px, 1fr));
    }

    article {
        h2 {
            margin-bottom: 3rem;
        }

        &:first-child {
            @include placeCenter();

            div {
                height: 100%;
            }
        }

        &:last-child {
            div {
                width: 100%;
            }
        }

        img {
            text-align: center;
            object-fit: cover;
            display: block;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            max-height: 460px;
            @media (max-width: 640px) {
                width: 100%;
            }

        }
    }
}