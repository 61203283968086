.preloader-wrap {
    background-color: #EDF2F6;
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 999999;
    top: 0
}

.preloader-spinner {
    left: 50%;
    margin: -20px 0 0 -20px;
    top: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    text-align: center;
    -webkit-animation: zion-rotate 2s infinite linear;
    animation: zion-rotate 2s infinite linear
}

.preloader-dot1, .preloader-dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #072248;
    border-radius: 100%;
    -webkit-animation: zion-bounce 2s infinite ease-in-out;
    animation: zion-bounce 2s infinite ease-in-out
}

.preloader-dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1s;
    animation-delay: -1s
}

@-webkit-keyframes zion-rotate {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes zion-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes zion-bounce {
    0%, 100% {
        -webkit-transform: scale(0)
    }

    50% {
        -webkit-transform: scale(1)
    }
}

@keyframes zion-bounce {
    0%, 100% {
        transform: scale(0);
        -webkit-transform: scale(0)
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1)
    }
}