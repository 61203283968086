.raspored {
    article {
        h4 {
            font-size: 2rem;
        }

        h5 {
            font-size: 1.8rem;
            line-height: 2em;
        }

        ul {
            li {
                padding: .5rem 1rem;
                border: 1px solid $lightGray;
                transition: $transition;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }

                a {
                    display: block;
                    transition: $transition;
                }

                svg {
                    margin-right: 1rem;
                }

                &:hover {
                    background-color: $lightGray;

                    a {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
}