@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

$montserrat: "Montserrat", sans-serif;
$roboto: "Roboto", sans-serif;
//COLORS
$primaryColor: #072248;
$white: #ffffff;
$black: #000000;
$active: #ef3139;
$gray: #CCCCCC;
$lightGray: #e2e1e1;
$yellow: rgb(231, 167, 12);
//PROPERTY VALUE
$transition: .3s linear;
$boxShadow: 0px 0px 5px 5px rgba($black, .4);
$boxLightShadow: 0px 0px 3px 3px rgba($black, .4);