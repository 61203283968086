.footer {
    border-top: 3px solid $active;
    background-color: rgba($black, .95);
    color: $white;
    font-size: 1.4rem;

    .logo {
        display: flex;
        gap: 1rem;
        margin-bottom: 2rem;
        align-items: center;
        line-height: 1.3;
        flex-direction: column;
        justify-content: flex-start;

        img {
            height: 100px;
        }

        h4 {
            text-align: center;
            text-transform: uppercase;
            line-break: loose;
            padding: 0 10%;
            font-size: 1.8rem;
            letter-spacing: 1px;
        }
    }

    &_wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;

        &_col {
            flex: 1 0 210px;

            &:nth-child(1) {
                flex: 1 0 280px;
            }

            h5 {
                font-size: 1.8rem;
                margin-bottom: 10px;
                letter-spacing: 1px;
            }

            & > *:not(h5) {
                line-height: 2;
                font-size: 1.6rem;
            }
        }

        img {
            max-width: 100%;
            height: 60px;
        }

        &_brand {
            flex: 1 0 280px;
        }
    }

}