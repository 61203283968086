.studyProgram {
    &.grid-3-1 {
        grid-template-columns:minmax(auto, 11fr) minmax(290px, 1fr);
        gap: 4rem;
        @include breakpoint-up-to($lg) {
            grid-template-columns:  1fr;
        }

    }

    article {
        text-align: justify;

        h3 {
            line-height: 3;
            color: $primaryColor;
            font-weight: 700;
        }

    }

    aside {
        min-width: 290px;

        ul {
            li {
                display: flex;
                justify-content: space-between;
                //flex-direction: column;
                border-bottom: 1px solid $gray;
                line-height: 2.5;

                span {
                    &:nth-child(1) {
                        font-weight: 600;
                    }

                    &:nth-child(2) {
                        align-self: flex-end;
                    }
                }
            }
        }
    }

    &.table {
        overflow-x: scroll;
    }

    table {
        width: 100%;
        min-width: 400px;
        @include breakpoint-up-to($sm) {
            font-size: 1.3rem;
            line-height: 1.1;
        }

        thead {
            background-color: $primaryColor;
            color: $white;
        }

        th, td {
            padding: 1rem .5rem;
            border: 1px solid $black;

            @include breakpoint-up-to($sm) {
                padding: 1rem .3rem;
            }

            &:not(:nth-child(2)) {
                text-align: center;
            }

            &.godina {
                text-align: left;
            }
        }
    }

    .active {
        background-color: rgba($primaryColor, .2);
    }


}