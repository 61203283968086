.osoblje {
    &_single {
        &.grid-1-3 {
            @include breakpoint-up-to($md) {
                grid-template-columns: 1fr;
            }
        }

        img {
            width: 100%;
            min-width: 280px;
        }

        h2 {
            color: $primaryColor;
            line-height: 1.3;

            span {
                font-size: .7em;
                font-weight: 400;
                color: $active;
                margin-left: 1rem;
            }

            margin-bottom: 2rem;
        }

        p {
            span {
                font-weight: 700;
                margin-right: 1rem;
            }
        }

        .obrazovanje {
            margin: 2rem 0;

            li {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-template-areas: "godina tema" "godina ustanova";

                span {

                    &:nth-child(1) {
                        padding: 0 2rem 2rem 0;
                        grid-area: godina;
                        margin-right: 2rem;
                        position: relative;
                        font-weight: 700;

                        &:before {
                            content: "";
                            display: block;
                            height: 100%;
                            width: .5rem;
                            position: absolute;
                            background-color: rgba($gray, .8);
                            left: 100%;
                            transform: translateX(-50%);
                            //border-radius: 1rem;
                        }

                        &:after {
                            content: "";
                            display: block;
                            height: 1em;
                            width: 1em;
                            background-color: $primaryColor;
                            border-radius: 50%;
                            position: absolute;
                            left: 100%;
                            top: 0;
                            transform: translate(-50%, 50%);
                        }
                    }

                    &:nth-child(2) {
                        grid-area: tema
                    }

                    &:nth-child(3) {
                        grid-area: ustanova;
                        padding-bottom: 2rem;
                    }
                }
            }
        }
    }
}