.kontakt {
    line-height: 1.3;
    grid-template-areas: "leftSide rightSide";
    @include breakpoint-up-to(980px) {
        grid-template-columns: 100%;
        grid-template-areas: "rightSide rightSide";
        gap: 0;
    }

    article {
        &:nth-of-type(1) {
            grid-area: leftSide;
            @include breakpoint-up-to(980px) {
            }

            img {
                height: 100%;
                display: block;
                object-fit: cover;
            }
        }

        &:nth-of-type(2) {
            grid-area: rightSide;
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }

        & > div {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;
            @include breakpoint-up-to($md) {
                grid-template-columns: 1fr;

            }

            dl {
                box-shadow: 2px 2px 20px 1px rgba($black, .4);
                padding: 1rem;


                dt {
                    font-weight: 700;
                }

                dd {
                    &:not(:last-child) {
                        margin-bottom: 2rem;
                    }

                    span {
                        display: inline-block;
                        width: 100%;
                    }
                }
            }
        }
    }
}

.contactForm {
    box-shadow: 2px 2px 20px 1px rgba($black, .4);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    input, textarea {
        border: 1px solid $gray;
    }

    .inputGroup {
        position: relative;

        &:hover {
            label {
                top: 0;
            }
        }

        label {
            position: absolute;
            padding: 0 1rem;
            left: 0;
            top: 0;
            transform: translate(0, 50%);
            transition: $transition;
            line-height: 1.5;
        }

        input, textarea {
            width: 100%;
            padding: 1.5rem 1rem;
            resize: none;

            &:not(:placeholder-shown), &:focus, &:active {
                & + label {
                    transform: translate(5px, -50%);
                    background-color: $primaryColor;
                    color: $white;
                    border-radius: 1rem;
                }
            }
        }


        span {
            position: absolute;
            display: block;
            right: 0;
            top: 0;
            transform: translateY(0);
            background-color: $active;
            padding: 2px 10px;
            border-radius: 15px 15px 15px 15px;
            color: $white;
            box-shadow: -0px 5px 8px 1px rgba($black, .5);
            animation-name: fadeInError;
            animation-duration: .3s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;
        }
    }

    .btn {
        align-self: flex-end;
    }

    @keyframes fadeInError {
        from {
            transform: translateY(-25%);
            opacity: 0;
        }
        to {
            opacity: 1;
            transform: translateY(-50%);
        }
    }
}