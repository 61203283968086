.listItem {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row;
    background: $primaryColor;
    padding: 10px;
    border-radius: 10px;
    color: #ffffff;
    transition: $transition;

    &:hover {
        background: rgba($primaryColor, .8);

    }

    span {
        font-size: 18px;
    }
}