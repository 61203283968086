.lastNews_sidebar {
    .wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        font-size: 1.4rem;
        background-color: $white;
    }

    h3 {
        background-color: rgba($primaryColor, 1);
        color: $white;
        padding: 1rem;
        margin-bottom: 2rem;
        border-radius: 10px;
    }

    .latestNews {
        display: grid;
        grid-template-columns: 60px 5fr;
        background-color: $white;
        cursor: pointer;
        transition: $transition;
        height: 60px;
        gap: 5px;

        &:hover {
            color: $active;

            img {
                transform: scale(1.2);
            }
        }

        .thumbnail {
            overflow: hidden;
            border-radius: 5px;

            img {
                height: 60px;
                width: 60px;
                aspect-ratio: 1;
                object-fit: cover;
                transition: transform $transition;
            }
        }

        .content {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            line-height: 1;
            font-size: 1.3rem;

            span {
                background-color: $primaryColor;
                color: $white;
                padding: .4rem;
                font-size: 1rem;
                border-radius: 5px;
            }
        }
    }
}

