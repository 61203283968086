* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html {
    font-size: 62.5%;
}


body {
    font-size: 1.6rem;
    line-height: 1;
    font-family: $roboto;
    min-width: 320px;
    background-color: $white;
}

h1 {
    font-size: 5rem;
}

h2 {
    font-size: 3.5rem;
}

h3 {
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 1px;
}

h4 {
    font-size: 1.6rem;
}

p {
    line-height: 1.6;

    & + .btn {
        margin-top: 1rem;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

ul {
    list-style: none;
    line-height: 1.6;
}

button, input, textarea, select {
    outline: none;
    border: none;
    background-color: transparent;
    font-family: $roboto;
}

.container {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
}

.py {
    padding: 10rem 0;

    &-top {
        padding: 10rem 0 0 0;

    }

    &-last {
        padding: 0 0 10rem 0;

    }

}

.text {
    &-center {
        text-align: center;
    }

    &-justify {
        text-align: justify;
    }
}

.place {
    &-center {
        @include placeCenter;
    }
}

.ck-powered-by-balloon {
    display: none !important;
}