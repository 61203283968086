.courseLevel {
    article {
        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        gap: 3rem;

        @include breakpoint-up-to($md) {
            grid-template-columns: 1fr 1fr;

        }
        @include breakpoint-up-to($mobile) {
            grid-template-columns: 1fr;

        }
    }

    .card {
        transition: $transition;
        cursor: pointer;
        box-shadow: 0px 0px 5px 5px rgba($black, .2);
        display: flex;
        flex-direction: column;

        &_body {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
            font-size: 1.3rem;

            h4 {
                font-size: 1.4rem;

            }
        }

        &:hover {
            box-shadow: $boxShadow;
        }
    }

    &_single {
        article {
            text-align: justify !important;
        }

        p {
            margin-bottom: 1rem;
        }

        ul {
            list-style-type: circle;
            list-style-position: inside;

            li {
                display: flex;
                align-items: center;

                &:before {
                    content: "";
                    display: block;
                    height: 1px;
                    width: 10px;
                    margin-right: 5px;
                    background-color: $black;

                }
            }
        }

        &_sp {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            padding-left: 5%;
            padding-right: 5%;
            text-align: justify !important;

            .accordion_footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;
                padding-top: 1rem;
                @include breakpoint-up-to($sm-plus) {
                    flex-direction: column;
                }

                div {
                    display: flex;
                    justify-content: flex-start;
                    gap: 1rem;
                    width: 100%;
                    @include breakpoint-up-to($sm-plus) {
                        flex-direction: column;
                    }
                }

                .btn_rounded-active {
                    color: $primaryColor;
                }
            }

        }


    }
}
