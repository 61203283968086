.documents {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 3rem;
    @include breakpoint-up-to(720px) {
        grid-template-columns: 1fr;

    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 2rem;
        object-fit: cover;
    }

    aside {
        @include breakpoint-up-to(720px) {
            display: none;
        }
    }

    article {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        overflow: hidden;
        gap: 1rem;

        a {
            svg {

                font-size: 1.8rem;
            }
        }
    }
}