@import "../../../styles/global/variables";
/*--------------------------------------------*/
/* Typography Variable */
/*--------------------------------------------*/
$body-font: "Archivo", sans-serif;
$title-font: "Archivo", sans-serif;

/*--------------------------------------------*/
/* Color Variable */
/*--------------------------------------------*/
$body-color: #777777;
// $primary-color: #ef3139;
// $primary-color: #20f592;
$primary-color: #ef3139;
$secondary-color: #022d62;
//$secondary-color: #ffff;
$title-color: #022d62;
//$title-color: #ffff;
$white-color: #ffffff;
$dark-color: #121212;
$light-color: #f6f6f6;
$border-color: #eeeeee;

/*--------------------------------------------*/
/* Other Variable */
/*--------------------------------------------*/
// $section-padding: 100px;
// $section-padding-sm: 70px;
// $section-padding-xs: 50px;

$radius-3: 3px;
$radius-5: 5px;
$radius-7: 7px;
$radius-12: 12px;
$radius-30: 30px;
$radius-50: 50px;
$radius-80: 80px;

$transition: all 0.25s ease-in-out;
$transition-2: all 0.2s ease-in-out;
$transition-3: all 0.3s ease-in-out;
$transition-4: all 0.4s ease-in-out;
$transition-5: all 0.5s ease-in-out;
/*------------------------------------*/
/* Error 404 */
/*------------------------------------*/
.error-404 .title {
    margin-top: 30px;
}

.digit {
    font-size: 230px;
    font-weight: 600;
}

.fill-primary {
    fill: $primary-color;
}

.fill-dark {
    fill: $title-color;
}

.errorPage-404 {
    //background-color: $primaryColor;
    padding-top: 0rem;

    article {
        padding: 20rem 0 10rem;
        background-color: $white;
        text-align: center;

        h2 {
            color: $primaryColor;
            margin: 3rem 0;
        }

        h6 {
            font-size: 1.6rem;
            color: $primaryColor;
            font-weight: 500;
        }

        .btn {
            margin-top: 3rem;
        }
    }

    .w-100 {
        width: 80%;
    }

    .h-100 {
        height: 100%
    }
}