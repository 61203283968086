.slider {
    &_news {
        &-nav {

            display: flex;
            gap: 1rem;

            button {
                color: $white;
                font-size: 3rem;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}