.dekanat {
    .teamCard_wrapper {
        margin: 5rem auto;
        min-height: 50vh;
        grid-auto-rows: 1fr;
        pointer-events: none;
        $gap: 7rem;
        gap: $gap;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        & > * {
            pointer-events: auto;

            img {
                transition: $transition all;
            }
        }

        &:hover {

            //& > * {
            //    img {
            //        filter: grayscale(2) contrast(.5);
            //    }
            //
            //    &:hover {
            //        img {
            //            filter: grayscale(0);
            //        }
            //    }
            //}
        }

        @include breakpoint-up-to(1000px) {
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: 400px;
        }
        @include breakpoint-up-to($sm) {
            grid-template-columns: repeat(1, 1fr);
            grid-auto-rows: 400px;
        }
    }

}

.teamCard {
    min-width: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
        .teamCard_info {
            transform: translateY(-60%);
            width: 100%;
        }
    }

    &_img {

        position: relative;

        &:after {
            //content: "";
            position: absolute;
            inset: 0;
            display: block;

        }

        img {

            box-shadow: $boxLightShadow;
            width: 100%;
            height: 350px;
            object-fit: cover;
            object-position: center top;
            display: block;
            border-radius: 1rem;
        }
    }

    &_info {
        position: relative;
        padding: 1rem;
        width: 95%;
        margin: -10% auto 0;
        background: linear-gradient(rgba($primaryColor, .9), rgba($primaryColor, .9));
        color: $white;
        border-radius: 1rem;
        text-align: center;
        text-shadow: 0 0 3px $black;
        flex: 1;
        min-height: 105px;
        transition: $transition;

        h4 {

            transition: $transition;
            line-height: 1.3;
            margin-bottom: .5rem;

            span {
                margin-right: 1rch;
            }
        }

        h3 {
            line-height: 1.3;
            //text-transform: uppercase;
            text-align: center;

            span {
                //text-transform: none;
                margin-right: 1rch;
            }

        }

        cite {
            width: 100%;
            display: inline-block;
            text-align: right;
            margin-top: .5rem;
        }
    }

}
