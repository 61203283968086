.bg {
    &-light {
        background-color: $white
    }
}

.badge {
    display: inline-block;
    padding: .5em 1em;
    border-radius: 5px;
    cursor: pointer;

    &-warning {
        background-color: $yellow;
    }
}