.about {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;

    &_wrapper {

        &:nth-child(even) {
            //grid-template-areas: "text title";
            //grid-template-columns:4fr 1fr;

        }
    }

    h2 {
        line-height: 1;
        font-size: 3rem;
        grid-area: title;
        display: flex;
        align-items: center;
        flex-grow: 1;
        gap: 1rem;
        margin-bottom: 2rem;
        color: $primaryColor;

        &:after {
            content: "";
            display: inline-block;
            height: 3px;
            background-color: $active;
            flex: 1;
        }
    }

    &_description {
        grid-area: text;

        & > * {
            //font-weight: 400;
        }

        text-align: justify;
    }

    h3, h4, h5, h6 {
        //font-weight: 700;
        text-transform: uppercase;
        line-height: 2;
    }

    p {
        font-weight: 400;
        text-transform: none;
    }
}