.errors {
    &-204 {
        text-align: center;

        h3 {
            font-size: 3rem;
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 2rem;
        }
    }
}