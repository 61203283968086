.bottomBar {
    border-top: 1px solid rgba($white, .05);
    background-color: rgba($black, .95);
    color: $white;
    text-align: center;
    padding: 1rem 0;

    p {
        font-size: 1.2rem;
    }
}