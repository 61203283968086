.accordion {
    //border: 1px solid $primaryColor;
    border-radius: 10px;

    &:not(:last-child) {
        margin-bottom: 2rem;
    }

    &_title {
        padding: 2rem;
        border-radius: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border: 1px solid $primaryColor;

        h4 {
            flex-grow: 1;
        }

        span {
            font-weight: 700;
            font-size: 2rem;
            color: inherit;
            display: flex;
            justify-content: center;
            align-items: center;

            &.active {
                color: $white;


            }
        }
    }

    background-color: $white;

    &_body {
        border-radius: 0 0 10px 10px;
        transform-origin: top center;
        overflow: hidden;

        & > div {
            padding: 20px;

            & * {
                line-height: 1.4;
            }
        }

        img {
            width: 100%;
            max-height: 300px;
            object-fit: contain;
            margin-top: 20px;
        }

        ol, ul {
            list-style-position: inside;

        }
    }

}
