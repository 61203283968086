.social {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 999;
    cursor: pointer;
    color: $white;

    .circle {
        background-color: $active;
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        position: relative;
        z-index: 100;
        transition: color $transition;
    }

    .icons {
        border-radius: 2rem;
        transform-origin: right center;
        position: absolute;
        top: 0;
        right: 0;
        visibility: hidden;
        overflow: hidden;
        z-index: 99;
    }

    &:hover {
        ul {
            transform: translateX(0);

            li {
                animation-name: twist-forward;
                animation-delay: .5s;
                transition-delay: .5s;

                &:not(:first-child) {
                    margin-left: .5rem;
                }
            }
        }

        .icons {
            visibility: visible;
        }

        .circle {
            color: $primaryColor;
        }
    }

    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 1.5s ease-in-out;
        transform: translateX(100%);
        padding-right: 5rem;
        z-index: 98;

        li {
            background-color: $active;
            font-size: 2rem;
            height: 2em;
            width: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            animation-duration: 1s;
            animation-name: twist-backward;
            animation-timing-function: linear;
            transition: margin 1s ease-in-out;
            transition-delay: 0s;
            @keyframes twist-backward {
                0% {
                    transform: rotate(0deg);
                }
                50% {
                    transform: rotate(180deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            @keyframes twist-forward {
                0% {
                    transform: rotate(0deg);
                }
                50% {
                    transform: rotate(-180deg);
                }
                100% {
                    transform: rotate(-360deg);
                }
            }

            a {
                line-height: 1;
                display: block;
            }

            & > * {
                position: relative;
                z-index: 2;
            }

            &:last-child {
                border-radius: 0 3rem 3rem 0;
            }

            &:first-child {
                border-radius: 3rem 0 0 3rem;
            }

            border-radius: 3rem !important;

            &:hover {
                //color: $primaryColor;

                &:after {
                    transform: scale(1);
                }
            }

            &:after {
                content: "";
                height: 4rem;
                width: 4rem;
                background-color: $primaryColor;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 3rem;
                z-index: 1;
                transform: scale(0);
                transition: $transition;
            }
        }
    }
}